
const Parse = require("parse");



// initialize parse server
let isParseInitialized = false;

export const initializeParse = async () => {
  if (!isParseInitialized) {
    Parse.initialize(
      process.env.GATSBY_PARSE_APPLICATION_ID,
      process.env.GATSBY_PARSE_JAVASCRIPT_KEY
    );
    Parse.serverURL = process.env.GATSBY_PARSE_REST_API_URL;
    console.log("Parse initialized");
    isParseInitialized = true;
  }
};


//Live query initialize
let liveQueryClient = null;

export const initializeLiveQuery = async () => {
  if (!liveQueryClient) {
    liveQueryClient = new Parse.LiveQueryClient({
      applicationId: process.env.GATSBY_PARSE_APPLICATION_ID,
      serverURL: process.env.GATSBY_PARSE_SERVER_LIVE_URL,
      javascriptKey: process.env.GATSBY_PARSE_JAVASCRIPT_KEY,
    });
    liveQueryClient.open();
    console.log("LiveQuery client initialized");
  }
  return liveQueryClient;
};

export const getLiveQueryClient = async () => {
  if (!liveQueryClient) {
    initializeLiveQuery();
  }
  return liveQueryClient;
};

//create user session
export const becomeUser = async (sessionToken) => {
  try {
    const user = await Parse.User.become(sessionToken);
    return user;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }


  }
};

//check current user 
export const currentUser = async () => {
  const user = await Parse.User.currentAsync();
  return user ? user.toJSON() : null;
};

//logout user
export const logout = async () => {
  Parse.User.logOut()
  return true
}

//Request GUID
export const getGUID = async (quoteID, successUrl, cancelUrl, civilID, sessionToken, lang, isNb, isOmantel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("getPGCheckoutSession", {
      quoteID: Number(quoteID),
      civilID: Number(civilID),
      successURL: successUrl,
      cancelURL: cancelUrl,
      sessionToken: sessionToken,
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//request Transaction status
export const getTransactionStatus = async (quoteID, requestID, sessionToken, civilId, lang, isNb, isOmantel, journeyType) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("getPGTransactionStatus", {
      quoteID: quoteID,
      requestID: requestID,
      sessionToken: sessionToken,
      civilID: Number(civilId),
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel,
      partner: journeyType
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//retrieve quote
export const getRetrieveQuote = async (carPlate, chars, civilID, partnerTrnReferenceNumber, utm_source, utm_medium, utm_campaign, utm_term, lang, policyId) => {
  try {
    let vechiclePlate = chars + carPlate;
    let createQuoteResp = await Parse.Cloud.run("retrieveQuote", {
      carPlate: vechiclePlate,
      civilID: Number(civilID),
      partnerTrnReferenceNumber: partnerTrnReferenceNumber,
      utmParams: `utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}`,
      lang: lang,
      policyID: policyId
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//update contact details
export const updateContactDetails = async (civilId, quoteId, moileNo, emailId, sessionToken, lang, isNB, isOmantel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("modifyContactDetails", {
      civilID: Number(civilId),
      quoteID: Number(quoteId),
      MobileNo: moileNo,
      EmailId: emailId,
      sessionToken: sessionToken,
      lang: lang,
      isNB: isNB,
      isOmanTel: isOmantel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//get plateCode db data
export const getPlateCodeData = async () => {
  try {
    const CountryMaster = Parse.Object.extend('PlateCode');
    const query = new Parse.Query(CountryMaster);
    query.equalTo("active", true);
    query.ascending("sortNumber");
    query.limit(2000);
    const results = await query.find();
    // return results;
    var DataArr = [];
    if (results) {
      results.map((data) => {
        DataArr.push(data.get("nameEn"));
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// additional drivers 
export const updateDriver = async (civilId, quoteId, action, sessionToken, driversArray, lang, isNb, isOmantel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("modifyDrivers", {
      civilID: Number(civilId),
      quoteID: Number(quoteId),
      action: action,
      sessionToken: sessionToken,
      additionalDrivers: driversArray,
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//update optional covers
export const updateCovers = async (civilId, quoteId, action, sessionToken, coversArray, status, lang, isNb, isOmantel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("modifyCovers", {
      civilID: Number(civilId),
      quoteID: Number(quoteId),
      action: action,
      sessionToken: sessionToken,
      optionalCovers: coversArray,
      status: status,
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


export const updateQuote = async (quoteId, civilId, sessionToken, tarrifCode, schemeCode, lang, isNb, isOmantel, journey, distChannel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("updateQuote", {
      quoteID: Number(quoteId),
      civilID: Number(civilId),
      sessionToken: sessionToken,
      tariffCode: tarrifCode,
      schemeCode: schemeCode,
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel,
      partner: journey,
      distChannel: Number(distChannel) || 0
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//get user info from sessiontoken
export const getUserInfo = async (quoteId, civilId, sessionToken, lang, isNb, isOmantel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("getUserInfo", {
      quoteID: quoteId,
      civilID: civilId,
      sessionToken: sessionToken,
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//get vechicle model
export const getVechicleModel = async (className, makeNo) => {
  try {
    const CountryMaster = Parse.Object.extend(className);
    const query = new Parse.Query(CountryMaster);
    query.equalTo("active", true);
    query.ascending("sortNumber");
    query.limit(2000);

    if (makeNo != '' && makeNo != null) {
      query.equalTo("value", makeNo);
    }

    const results = await query.find();
    return results;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

export const getVechicleMake = async (className, makeNo, modelNo) => {
  try {
    const CountryMaster = Parse.Object.extend(className);
    const query = new Parse.Query(CountryMaster);
    query.equalTo("active", true);
    query.equalTo("make", makeNo);
    query.limit(2000);
    // query.ascending("sortNumber");
    if (modelNo != '' && modelNo != null) {
      query.equalTo("value", modelNo);
    }
    // query.equalTo("value", modelNo);
    const results = await query.find();
    return results;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//get cover table
export const getCoverTable = async () => {
  try {
    const DataTable = Parse.Object.extend('Cover');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.mappingCode = data.get("coverMappingCode");
        obj.shortTitleEn = data.get("shortTitleEn");
        obj.shortTitleAr = data.get("shortTitleAr");
        obj.titleEn = data.get("titleEn");
        obj.titleAr = data.get("titleAr");
        obj.nameEn = data.get("bulletsEn");
        obj.nameAr = data.get("bulletsAr");
        obj.summaryEn = data.get("summaryEn");
        obj.summaryAr = data.get("summaryAr");
        obj.iconName = data.get("iconName");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//get label data from table
export const getLabelTable = async () => {
  try {
    const DataTable = Parse.Object.extend('ApplicationLabels');
    const query = new Parse.Query(DataTable);
    query.limit(2000);
    // query.equalTo("active", true);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.key = data.get("key");
        obj.nameEn = data.get("valueEn");
        obj.nameAr = data.get("valueAr");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//get validation error message from table
export const getValidationErrorTable = async () => {
  try {
    const DataTable = Parse.Object.extend('ValidationErrorMessages');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.key = data.get("key");
        obj.nameEn = data.get("valueEn");
        obj.nameAr = data.get("valueAr");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//get legalInfor from table
export const getLegalInfo = async () => {
  try {
    const DataTable = Parse.Object.extend('StaticContent');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.key = data.get("key");
        obj.nameEn = data.get("nameEn");
        obj.nameAr = data.get("nameAr");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

export const normalQuery = async (reqId, className) => {
  try {
    const DataTable = Parse.Object.extend(className);
    const query = new Parse.Query(DataTable);
    query.equalTo("requestID", reqId);
    query.equalTo("status", 'Completed');
    const results = await query.find();
    return results;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//get SEO from table
export const getSeo = async (page) => {
  try {
    const DataTable = Parse.Object.extend('SEO');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    console.log(results, 'seo')
    var DataArr = [];
    if (results) {
      const resultsFiltered = results.filter((resultElement) => { return resultElement.get("page") === page })
      resultsFiltered.map((data) => {
        var obj = {};
        obj.pageName = data.get("page");
        obj.keywords = data.get("keywords");
        obj.siteName = data.get("siteName");
        obj.title = data.get("title");
        obj.description = data.get("description");
        obj.image = data.get("openGraphImages");
        obj.titleAr = data.get("titleAr");
        obj.descriptionAr = data.get("descriptionAr")
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//create policy document
export const getPolicyDocument = async (quoteId, policyNo, sessionToken, lang, isNb, isOmantel) => {
  try {
    if (policyNo === 32426) {
      quoteId = 414048
      policyNo = 2063965
    } else {
      quoteId = Number(quoteId)
      policyNo = Number(policyNo)
    }
    let createQuoteResp = await Parse.Cloud.run("retrievePolicyDocument", {
      quoteID: quoteId,
      policyNo: policyNo,
      // quoteID:414048,
      // policyNo:2063965,
      sessionToken: sessionToken,
      lang: lang,
      isNB: isNb,
      isOmanTel: isOmantel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//get nationality data from table
export const getNationality = async () => {
  try {
    const DataTable = Parse.Object.extend('Nationality');
    const query = new Parse.Query(DataTable);
    query.limit(2000);
    // query.equalTo("active", true);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.value = data.get("value");
        obj.nameEn = data.get("nameEn");
        obj.nameAr = data.get("nameAr");
        obj.countryEn = data.get("countryEn");
        obj.countryAr = data.get("countryAr");
        obj.isoCode = data.get("isoCode");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//get prequote sessiontoken
export const getPreQuote = async (carPlateText, carPlateNo, civilID, mobile, oTPVerification) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("updatePreQuoteDetails", {
      carPlateText: carPlateText.toString(),
      carPlateNo: carPlateNo.toString(),
      civilID: Number(civilID),
      mobile: mobile.toString(),
      oTPVerification: oTPVerification
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

export const getROPdata = async (carPlateCode, carPlateNumber, civilID, sessionToken, apiType, journey) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("retrieveROP", {
      carPlateCode: carPlateCode,
      carPlateNumber: carPlateNumber.toString(),
      civilID: Number(civilID),
      sessionToken: sessionToken,
      apiType: apiType,
      partner: journey
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


//update Main driver data 
export const updateMainDriver = async (sessionToken, driverName, driverDOB, driverAge, drivingExp, driverEmail, driverGender, nationality, civilID, isOmanTel, journeyType) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("updateMainDriverDetails", {
      driverName: driverName,
      driverDOB: driverDOB,
      driverAge: driverAge,
      drivingExp: drivingExp,
      driverEmail: driverEmail,
      driverGender: driverGender,
      nationality: nationality,
      civilID: Number(civilID),
      sessionToken: sessionToken,
      isOmanTel: isOmanTel,
      partner: journeyType
      // driverName:'raja',
      // driverDOB:'1992-11-10 00:00:00',
      // driverAge:32,
      // drivingExp:2,
      // driverEmail:'hh@gmail.com',
      // driverGender:'M',
      // nationality:200,
      // civilID:11804227,
      // sessionToken:'r:b85c4547fb8b694b158e6684a39c4740',
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


//update vechicle details data 
export const updateVehicleDetails = async (sessionToken, civilID, VehicleMake, MakeCode, VehicleModel, ModelCode, VehicleYear, isOmanTel, ChassisNumber, EngineNumber, ColorCode, journeyType) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("updateVehicleDetails", {
      civilID: Number(civilID),
      VehicleMake: VehicleMake,
      MakeCode: Number(MakeCode),
      VehicleModel: VehicleModel,
      ModelCode: Number(ModelCode),
      VehicleYear: Number(VehicleYear),
      sessionToken: sessionToken,
      isOmanTel: isOmanTel,
      ChassisNumber: ChassisNumber,
      EngineNumber: EngineNumber,
      ColorCode: Number(ColorCode),
      partner: journeyType
      // civilID:2420425,
      // VehicleMake:'VOLVO',
      // MakeCode:113,
      // VehicleModel:'XC 90 TURBO',
      // ModelCode:24,
      // VehicleYear:2022,
      // sessionToken:'r:213fb9d2af994b0c27269a988f1bbe0b',
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


///create quote
export const createNewQuote = async (civilID, sessionToken, isOmanTel, journey, schemaCode, tarrifCode, distChannel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("createQuote", {
      civilID: Number(civilID),
      sessionToken: sessionToken,
      isOmanTel: isOmanTel,
      partner: journey,
      schemeCode: Number(schemaCode),
      tariffCode: Number(tarrifCode),
      distChannel: Number(distChannel) || 0

      // isOmanTel:false
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

///green check 
export const greenCheck = async (civilID, sessionToken, mobile, quoteID) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("greenCustomerVerification", {
      mobileNo: Number(mobile),
      sessionToken: sessionToken,
      civilID: Number(civilID),
      quoteID: Number(quoteID)
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

///green check edit 
export const greenCheckEdit = async (quoteID, sessionToken, civilID, driverName, driverDOB, driverEmail, driverGender, nationality, additionalDrivers, vehicleDetails, mobile, exp, age) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("greenCustomerVerification", {
      quoteID: Number(quoteID),
      sessionToken: sessionToken,
      civilID: Number(civilID),
      driverName: driverName,
      driverDOB: driverDOB,
      // driverEmail:driverEmail,
      driverGender: driverGender,
      nationality: nationality,
      // additionalDrivers:additionalDrivers,
      // vehicleDetails:vehicleDetails,
      mobileNo: Number(mobile),
      // drivingExp:Number(exp),
      // driverAge:Number(age)
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//////eligibiltiy check 
export const eligibilityCheck = async (civilID, sessionToken, quoteid, mobileNo) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("validateOmanTelEMI", {
      quoteID: Number(quoteid),
      sessionToken: sessionToken,
      civilID: Number(civilID),
      mobileNo: Number(mobileNo)
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


//get quote doc

export const getQuoteDoc = async (quoteID, sessionToken, isOmanTel) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("retrieveQuoteDocument", {
      quoteID: Number(quoteID),
      sessionToken: sessionToken,
      isOmanTel: isOmanTel
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//update to comprehensive plan
export const updateComp = async (civilID, quoteID, userName, premium, sessionToken, isOmanTel, journeyType) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("OptForComprehensivePlan", {
      quoteID: Number(quoteID),
      sessionToken: sessionToken,
      civilID: Number(civilID),
      userName: userName,
      premium: premium,
      isOmanTel: isOmanTel,
      partner: journeyType
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


//updateChasis
export const updateChasis = async (quoteID, sessionToken, civilID, ColorCode, ChassisNo, EngineNo, isOmanTel, journeyType) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("UpdateVehicleDetails", {
      quoteID: Number(quoteID),
      sessionToken: sessionToken,
      civilID: Number(civilID),
      ColorCode: ColorCode,
      ChassisNo: ChassisNo,
      EngineNo: EngineNo,
      isOmanTel: isOmanTel,
      partner: journeyType
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}


//get preQuote data
export const getPreQuoteData = async (session, civilId) => {
  try {
    // const DataTable = Parse.Object.extend('PreQuoteDetails');
    // const query = new Parse.Query(DataTable);
    const query = new Parse.Query("PreQuoteDetails");
    query.limit(2000);
    // query.equalTo("sessionToken", session);
    query.equalTo("civilID", Number(civilId));
    query.descending('createdAt');
    const object = await query.first();
    // const results = await query();
    console.log(object.get("mainDriverROP"), 'result')
    var DataArr = [];
    if (object) {
      // object.map((data) => {
      var obj = {};
      obj.mainDriver = object.get("mainDriverROP");
      obj.mainDriver2 = object.get("mainDriver");
      obj.vehicleDetails = object.get("vehicleDetailsROP");
      obj.vehicleDetails2 = object.get("vehicleDetails");
      obj.civilID = object.get("civilID");
      obj.sessToken = object.get("sessionToken");
      obj.carPlateText = object.get("carPlateText");
      obj.carPlateNumber = object.get("carPlateNumber");
      obj.mobileNo = object.get("mobileNo")
      DataArr.push(obj);
      // });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }

  }
};


//get user info table 
export const getUserTable = async (session) => {
  try {
    // const DataTable = Parse.Object.extend('PreQuoteDetails');
    // const query = new Parse.Query(DataTable);
    const query = new Parse.Query("UserInfo");
    query.limit(2000);
    query.equalTo("civilID", Number(session));
    // const object = await query.first();
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.nationalityCode = data.get("nationalityCode");
        obj.dob = data.get("dob");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};




export const editDriverAndVehicle = async (quoteID, sessionToken, civilID, driverName, driverDOB, driverEmail, driverGender, nationality, additionalDrivers, vehicleDetails, mobile, exp, age) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("updateUserandQuoteInfo", {
      quoteID: Number(quoteID),
      sessionToken: sessionToken,
      civilID: Number(civilID),
      driverName: driverName,
      driverDOB: driverDOB,
      driverEmail: driverEmail,
      driverGender: driverGender,
      nationality: nationality,
      additionalDrivers: additionalDrivers,
      vehicleDetails: vehicleDetails,
      mobile: Number(mobile),
      drivingExp: Number(exp),
      driverAge: Number(age)
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//get colors
export const getColors = async () => {
  try {
    // const DataTable = Parse.Object.extend('PreQuoteDetails');
    // const query = new Parse.Query(DataTable);
    const query = new Parse.Query("Color");
    query.limit(2000);
    query.equalTo("active", true);
    // const object = await query.first();
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.nameEn = data.get("nameEN");
        obj.nameAr = data.get("nameAR");
        obj.code = data.get("value");
        obj.colorCode = data.get('hexColor')
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//get prequote with name and nationality

export const getPreQuoteNF = async (carPlateText, carPlateNo, civilID, mobile, oTPVerification, nationality, name, utm_source, utm_medium, utm_campaign, utm_term, journeyType, branch, staff) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("updatePreQuoteDetailsNF", {
      carPlateText: carPlateText.toString(),
      carPlateNo: carPlateNo.toString(),
      civilID: Number(civilID),
      mobile: mobile.toString(),
      oTPVerification: oTPVerification,
      nationality: nationality,
      name: name,
      utmParams: `utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}`,
      partner: journeyType,
      brokerBranch: branch || "0",
      brokerStaff: staff || "0"
      // nationality:13,
      // name:'john doe'
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//omantel addition API
export const addOmantel = async (sessionToken, civilID, quoteID, policyNo, mobileNo) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("addOmanTelEMI", {
      sessionToken: sessionToken,
      civilID: Number(civilID),
      quoteID: Number(quoteID),
      policyNo: Number(policyNo),
      mobileNo: Number(mobileNo),
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//search quote
export const searchQuote = async (mobileNo, quoteID, utm_source, utm_medium, utm_campaign, utm_term, journey) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("retrieveNBQuoteNB", {
      mobileNo: Number(mobileNo),
      quoteID: Number(quoteID),
      utmParams: `utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}`,
      partner: journey
      // quoteID:105171,
      // mobileNo:32234234,
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

// get all model and make
export const getAllVehicleModal = async (className) => {
  try {
    const CountryMaster = Parse.Object.extend('Model');
    const query = new Parse.Query(CountryMaster);
    query.equalTo("active", true);
    // query.ascending("sortNumber");
    query.limit(12000);
    // if(makeNo != '' && makeNo != null){
    //   query.equalTo("value", makeNo);
    // }
    const modelData = await query.find();
    let modelList = [];
    let modelObj;
    if (modelData) {
      modelData.map((data) => {
        modelObj = {
          'modelEn': data.get("nameEn"),
          'modelAr': data.get("nameAr"),
          'modelCode': data.get("value"),
          'makeCode': data.get('make')
        }
        modelList.push(modelObj);
        // setVechicleModel(model)
      })
    }
    return modelList;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

export const getAllVechicleMake = async (className) => {
  try {
    const CountryMaster = Parse.Object.extend('Make');
    const query = new Parse.Query(CountryMaster);
    query.equalTo("active", true);
    // query.equalTo("make", makeNo);
    query.limit(2000);
    // query.ascending("sortNumber");
    // if(modelNo != '' && modelNo != null){
    //   query.equalTo("value", modelNo);
    // }
    // query.equalTo("value", modelNo);
    const makeData = await query.find();
    let makelist = []
    let makeObj;
    console.log(makeData, 'makeData')
    if (makeData) {
      makeData.map((data) => {
        makeObj = {
          'makeEn': data.get("nameEn"),
          'makeAr': data.get('nameAr'),
          'makeIcon': data.get('iconName'),
          'makeId': data.get('value')
        }
        makelist.push(makeObj);
      })
    }
    return makelist;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};


//////manual policy creation 
export const createPolicy = async (quoteID, sessionToken, isNB, isOmanTel, journey) => {
  try {
    let createQuoteResp = await Parse.Cloud.run("createPolicy", {
      quoteID: Number(quoteID),
      sessionToken: sessionToken,
      isNB: isNB || false,
      isOmanTel: isOmanTel || false,
      partner: journey
    });
    return createQuoteResp;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//retrieve policy
export const getRetrievePolicy = async (carPlate, chars, civilID,) => {
  try {
    const vechiclePlate = chars + carPlate;
    return await Parse.Cloud.run("retrievePolicy", {
      carPlate: vechiclePlate,
      civilID: Number(civilID),
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// get retrieve policy info with sessiontoken
export const getRetrievePolicyInfo = async (civilId, sessionToken, policyID) => {
  try {
    return await Parse.Cloud.run("getRetrievePolicyInfo", {
      civilID: civilId,
      sessionToken: sessionToken,
      policyID: policyID
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//request OTP
export const sendOTP = async (mobileNumber, lang) => {
  try {
    return await Parse.Cloud.run("sendOTP", {
      mobileNo: mobileNumber,
      language: lang,
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//verify otp
export const verifyOtp = async (mobileNumber, otp, requestID) => {
  try {
    return await Parse.Cloud.run("verifyOTP", {
      requestID: requestID,
      OTP: otp,
      mobileNo: mobileNumber,
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//rop driver
export const ropDriver = async (buyerCivilID, sessionToken, trackId, carPlateCode, carPlateNumber, civilId) => {
  try {
    return await Parse.Cloud.run("retrieveROPDriver", {
      buyerCivilID: buyerCivilID,
      sessionToken: sessionToken,
      trackID: trackId,
      carPlateCode: carPlateCode,
      carPlateNumber: carPlateNumber,
      civilID: civilId,
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//update transfer Policy details
export const updateTransferPolicyDetails = async (
  trackId,
  policyID,
  policyNo,
  sessionToken,
  isVehicleFinanced,
  buyerDetails,
  additionalDrivers,
) => {
  try {
    return await Parse.Cloud.run("updateTransferRequest", {
      trackID: trackId,
      PolicyID: policyID,
      PolicyNo: policyNo,
      SessionToken: sessionToken,
      IsVehicleFinanced: isVehicleFinanced,
      BuyerDetails: buyerDetails,
      AdditionalDrivers: additionalDrivers,
    });

  } catch (e) {
    if (e.code === 209) {
      localStorage.clear();
      return null;
    } else {
      console.log(e);
      return null;
    }
  }
};

//transfer permium calculate
export const transferPremiumCalculation = async (trackID, policyID, policyNo, civilID, carPlateCode, carPlateNumber, sessionStorage, isPCRequired) => {
  try {
    return await Parse.Cloud.run("transferPremiumCalculation", {
      trackID: trackID,
      policyID: policyID,
      policyNo: policyNo,
      civilID: civilID,
      RegnNoText: carPlateCode,
      RegnNoNumber: carPlateNumber,
      sessionToken: sessionStorage,
      isPCReq: isPCRequired
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//get bank details
export const getBankTable = async () => {
  try {
    const DataTable = Parse.Object.extend('Bank');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.value = data.get("value");
        obj.nameEn = data.get("nameEn");
        obj.nameAr = data.get("nameAr");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// get retrieve policy info with sessiontoken
export const getTransferRequest = async (civilId, sessionToken, trackID) => {
  try {
    return await Parse.Cloud.run("getTransferRequestInfo", {
      civilID: civilId,
      sessionToken: sessionToken,
      trackID: trackID
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//upload transfer document
export const uploadTransferDoc = async (trackID, policyId, policyNo, civilId, sessionToken, requestType, documentType, document) => {
  try {
    return await Parse.Cloud.run("uploadDocument", {
      trackID: trackID,
      policyID: policyId,
      policyNo: policyNo,
      civilID: civilId,
      sessionToken: sessionToken,
      requestType: requestType,
      documentType: documentType,
      document: document
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//PG checkout PS
export const getPGCheckoutSessionPolicy = async (trackId, civilId, sessionToken, policyId, policyNo, successUrl, cancelUrl,) => {
  try {
    return await Parse.Cloud.run("getPGCheckoutSessionPs", {
      trackID: trackId,
      civilID: civilId,
      sessionToken: sessionToken,
      policyID: policyId,
      policyNo: policyNo,
      successURL: successUrl,
      cancelURL: cancelUrl
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//get status PS
export const getTransactionStatusPolicy = async (requestId, trackId, policyId, policyNo, sessionToken) => {
  try {
    return await Parse.Cloud.run("getPGTransactionStatusPs", {
      requestID: requestId,
      trackID: trackId,
      policyID: policyId,
      policyNo: policyNo,
      sessionToken: sessionToken
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// final transfer request
export const initiateTransferRequest = async (policyId, policyNo, civilId, sessionToken, premiumPayableAmount) => {
  try {
    return await Parse.Cloud.run("initiateTransfer", {
      policyID: policyId,
      policyNo: policyNo,
      civilID: civilId,
      sessionToken: sessionToken,
      premiumPayable: premiumPayableAmount
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// policy download PS
export const downloadPolicyPS = async (policyId, policyNo, civilId, isTransfer) => {
  try {
    return await Parse.Cloud.run("retrievePolicyDocumentPs", {
      policyID: policyId,
      policyNo: policyNo,
      civilID: civilId,
      isTransfer: isTransfer || false
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// renewal card download PS
export const renewalCardDownload = async (policyNo, civilId, carPlateCode, carPlateNumber, sessionToken) => {
  try {
    return await Parse.Cloud.run("renewalCardDocument", {
      policyNo: policyNo,
      civilID: civilId,
      RegnNoText: carPlateCode,
      RegnNoNumber: carPlateNumber,
      sessionToken: sessionToken
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// UpdateMobile number PS
export const updateMobileNumberPS = async (policyId, oldMobileNumber, NewMobileNumber, civilId, sessionToken) => {
  try {
    return await Parse.Cloud.run("updateMobileNumber", {
      policyID: policyId,
      oldMobNo: oldMobileNumber,
      newMobNo: NewMobileNumber,
      civilID: civilId,
      sessionToken: sessionToken,
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// RopReUpload
export const uploadRop = async (policyId, civilId, sessionToken) => {
  try {
    return await Parse.Cloud.run("ROPReUpload", {
      policyID: policyId,
      civilID: civilId,
      sessionToken: sessionToken,
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

//update Cancel Policy details
export const updateCancelPolicyDetails = async (
  trackId,
  policyID,
  policyNo,
  sessionToken,
  bankDetails,
  date,
  cancellationReason,
  accountOwner,
  iBann
) => {
  try {
    return await Parse.Cloud.run("updateCancelRequest", {
      trackID: trackId,// string
      policyID: policyID,//number
      policyNo: policyNo,// number
      sessionToken: sessionToken,//string
      bank: bankDetails,//number
      effectiveDate: date,//string
      cancellationReason: cancellationReason, //number
      accountHolderName: accountOwner, //string
      ibann: iBann //string
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear();
      return null;
    } else {
      console.log(e);
      return null;
    }
  }
};

//get bank details
export const getCancelReasonTable = async () => {
  try {
    const DataTable = Parse.Object.extend('CancellationReason');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.value = data.get("value");
        obj.nameEn = data.get("nameEn");
        obj.nameAr = data.get("nameAr");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
};

// get cancel policy info 
export const getCancelRequest = async (civilId, sessionToken, trackID, policyID, policyNo) => {
  try {
    return await Parse.Cloud.run("getCancelRequestInfo", {
      civilID: civilId,
      sessionToken: sessionToken,
      trackID: trackID,
      policyID: policyID || null,
      policyNo: policyNo || null,
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//cancel permium calculate
export const cancelPremiumCalculationPS = async (trackID, policyID, policyNo, civilID, sessionStorage, carPlateNo) => {
  try {
    return await Parse.Cloud.run("cancelPremiumCalculation", {
      trackID: trackID,
      policyID: policyID,
      policyNo: policyNo,
      civilID: civilID,
      sessionToken: sessionStorage,
      carPlate: carPlateNo
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//cancel init
export const initiateCancellationPS = async (trackID, policyID, policyNo, civilID, sessionStorage, bankDetails, accountOwner, iBann) => {
  try {
    return await Parse.Cloud.run("initiateCancellation", {
      trackID: trackID,
      policyID: policyID,
      policyNo: policyNo,
      civilID: civilID,
      sessionToken: sessionStorage,
      bank: bankDetails,
      accountHolderName: accountOwner,
      ibann: iBann
    });
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

export const getVehicleFinance = async () => {
  try {
    const DataTable = Parse.Object.extend('VehicleFinance');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    var DataArr = [];
    if (results) {
      results.map((data) => {
        var obj = {};
        obj.value = data.get("value");
        obj.nameEn = data.get("name");
        obj.nameAr = data.get("nameAr");
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }

  }
};

//Partner Details
export const getPartnerTable = async () => {
  try {
    const DataTable = Parse.Object.extend('Partners');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    let DataArr = [];
    if (results) {
      results.map((data) => {
        let obj = {};
        obj.discountMarketingCopyAr = data.get("discountMarketingCopyAr");
        obj.logo = data.get("logo");
        obj.slug = data.get("slug");
        obj.schemaCode = data.get("schemaCode");
        obj.tariffCode = data.get("tariffCode");
        obj.nameEn = data.get("nameEn");
        obj.nameAr = data.get("nameAr");
        obj.discountPercentage = data.get("discountPercentage");
        obj.phone = data.get("phone");
        obj.email = data.get("email");
        obj.whatsapp = data.get("whatsapp");
        obj.distChannel = data.get("distChannel")
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear();
      return null;
    } else {
      console.log(e);
      return null;
    }
  }
};

// get partner details 
export const getPartnerDetails = async () => {
  try {
    return await Parse.Cloud.run("getPartners");
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear()
      return null
    } else {
      console.log(e)
      return null
    }
  }
}

//Broker Parse Details
export const getBrokerTable = async () => {
  try {
    const DataTable = Parse.Object.extend('Broker');
    const query = new Parse.Query(DataTable);
    query.equalTo("active", true);
    query.limit(2000);
    const results = await query.find();
    let DataArr = [];

    if (results) {
      results.map((data) => {
        let obj = {};
        obj.logo = data.get("logo");
        obj.slug = data.get("slug");
        obj.schemaCode = data.get("schemaCode");
        obj.tariffCode = data.get("tarrifCode");
        obj.nameEn = data.get("nameEn");
        obj.nameAr = data.get("nameAr");
        obj.discountPercentage = data.get("discountPercentage");
        obj.phone = data.get("phone");
        obj.email = data.get("email");
        obj.whatsapp = data.get("whatsapp");
        obj.objectId = data.id
        obj.distChannel = data.get("distChannel")
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear();
      return null;
    } else {
      console.log(e);
      return null;
    }
  }
};

export const getBrokerBranch = async (brokerID) => {
  try {
    const Broker = Parse.Object.extend('Broker');
    const brokerPointer = new Broker();
    brokerPointer.id = brokerID;

    const DataTable = Parse.Object.extend('BrokerBranches');
    const query = new Parse.Query(DataTable);

    query.equalTo('active', true);
    query.equalTo('broker', brokerPointer);
    query.limit(2000);

    const results = await query.find();
    let DataArr = [];

    if (results) {
      results.map((data) => {
        let obj = {};
        obj.value = data.get('value');
        obj.name = data.get('name');
        obj.nameAr = data.get('nameAr');
        obj.brokerId = data.get('broker').id;
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear();
      return null;
    } else {
      console.error(e);
      return null;
    }
  }
};


//BrokerStaff Parse Details
export const getBrokerStaff = async (brokerID) => {
  try {
    const Broker = Parse.Object.extend('Broker');
    const brokerPointer = new Broker();
    brokerPointer.id = brokerID;

    const DataTable = Parse.Object.extend('BrokerStaff');
    const query = new Parse.Query(DataTable);

    query.equalTo('active', true);
    query.equalTo('broker', brokerPointer);
    query.limit(2000);

    const results = await query.find();
    let DataArr = [];

    if (results) {
      results.map((data) => {
        let obj = {};
        obj.value = data.get('value');
        obj.name = data.get('name');
        obj.nameAr = data.get('nameAr');
        obj.brokerId = data.get('broker').id;
        DataArr.push(obj);
      });
    }
    return DataArr;
  } catch (e) {
    if (e.code === 209) {
      localStorage.clear();
      return null;
    } else {
      console.error(e);
      return null;
    }
  }
};
